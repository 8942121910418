import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { UserService } from '../../shared/services/user.service';
import { ValidatorsService } from '../../shared/services/validators.service';

@Component({
  selector: 'reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent implements OnInit {
  @AutoUnsubscribe()
  queryParamsSub: Subscription | undefined;

  form: FormGroup = new FormGroup({
    token: new FormControl('', [Validators.required]),
    password: new FormControl('', this.validatorsService.passwordValidators(true, false))
  });

  get passwordControl() {
    return this.form.get('password')!;
  }

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public validatorsService: ValidatorsService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      const token = params['token'];
      if (token) {
        this.form.get('token')?.setValue(token);
      }
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.userService.resetPasswordRequest(this.form.value).subscribe((response) => {
      if (response.success) {
        this.toastrService.success('Password successfully changed!');
        this.router.navigateByUrl('/signin');
      }
    });
  }
}
