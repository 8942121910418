<div class="modal">
  <div class="header">
    <h2>Verify your Email</h2>

    <span class="icon-Close close-icon" title="Close" (click)="logout()"></span>
  </div>
  <mat-dialog-content class="modal-content">
    <p>Please check your inbox for the email verification link. You can only access your GAIMIN account upon successful verification.</p>

    <div class="button-group">
      <button class="button" [disabled]="(isResendEnabled$ | async) === false" (click)="resend()">
        Resend email
        <ng-container *ngIf="(isResendEnabled$ | async) === false">
          in&nbsp;
          <countdown [config]="config" (event)="handleCountdownEnd($event)"></countdown>
        </ng-container>
      </button>

      <button class="button" (click)="proceed()">
        Proceed
      </button>
    </div>
  </mat-dialog-content>
</div>
