<div class="auth">
  <img class="auth__logo" src="assets/logo.svg" alt="Gaimin" />
  <div class="auth__title">
    <h1>Reset password</h1>
  </div>

  <form [formGroup]="form" class="form">
    <input formControlName="token" class="form__field" type="hidden" />

    <div class="form__field-group">
      <label for="password"> Password</label>
      <div class="password-field">
        <input
          formControlName="password"
          [type]="validatorsService.isPasswordVisible ? 'text' : 'password'"
          class="form__field"
          id="password"
          #password />
        <span
          (click)="validatorsService.togglePasswordVisibility()"
          class="icon"
          [ngClass]="{
            'icon-Closed-Eye': !validatorsService.isPasswordVisible,
            'icon-Eye': validatorsService.isPasswordVisible
          }"></span>
      </div>

      <ng-container *ngIf="passwordControl.invalid && passwordControl.dirty">
        <span *ngIf="passwordControl.hasError('required')" class="form__error"> Password should be present </span>

        <ng-container
          *ngIf="
            passwordControl.hasError('minlength') || passwordControl.hasError('maxlength');
            else password_allowedPattern
          ">
          <span class="form__error">
            Password should have
            <mark>{{ validatorsService.passwordMinLength }} - {{ validatorsService.passwordMaxLength }}</mark>
            characters. You currently typed
            <mark>{{ passwordControl.value!.length }}</mark>
          </span>
        </ng-container>

        <ng-template #password_allowedPattern>
          <ng-container *ngIf="passwordControl.hasError('allowedPattern'); else password_complexity">
            <span class="form__error">{{ validatorsService.allowedPatternError }}</span>
          </ng-container>
        </ng-template>

        <ng-template #password_complexity>
          <ng-container *ngIf="passwordControl.hasError('complexity')">
            <span class="form__error">{{ validatorsService.complexityError }}</span>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>

    <button class="button" (click)="onSubmit()" [disabled]="form.invalid">Submit</button>
  </form>

  <app-loader></app-loader>
</div>
