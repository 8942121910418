import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import Openfort, { ThirdPartyOAuthProvider, TokenType } from '@openfort/openfort-js';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { LoaderService } from '../services/loader.service';
import { WalletPasswordModalComponent } from '../components/modals/wallet-password-modal/wallet-password-modal.component';
import { environment } from '../../../environments/environment';
import { WalletData, StatusResponse } from '../intarfaces';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  private openfort: Openfort;
  private openfortPlayerId?: string;
  private chainId: number;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private dialogService: MatDialog,
    private toastrService: ToastrService,
    private loaderService: LoaderService
  ) {
    this.openfort = new Openfort({
      baseConfiguration: {
        publishableKey: environment.openfort.publicKey
      },
      shieldConfiguration: {
        shieldPublishableKey: environment.openfort.shieldApiKey
      }
    });
    this.chainId = environment.openfort.chainId;
  }

  createOpenFortWallet() {
    const dialogRef = this.dialogService.open(WalletPasswordModalComponent, {
      panelClass: 'password-dialog-overlay',
      backdropClass: 'dialog-overlay-pane-dark',
      disableClose: true
    });
    dialogRef.componentRef?.instance.confirmRequest.subscribe(async (recoveryPassword) => {
      if (recoveryPassword) {
        this.loaderService.showLoader();
        console.log('Start wallet creation:');
        try {
          await this.authenticateWithThirdPartyProvider();
          await this.configureEmbeddedSigner(recoveryPassword);
          await this.getOpenfortAccount();
        } catch (error) {
          this.handleWalletCreationError();
          console.error('Error: ', error);
        }
      }
    });
  }

  async authenticateWithThirdPartyProvider() {
    console.log('Start authenticateWithThirdPartyProvider:');
    try {
      const accessToken = this.authService.accessToken;
      const response = await this.openfort.authenticateWithThirdPartyProvider({
        provider: ThirdPartyOAuthProvider.CUSTOM,
        token: accessToken,
        tokenType: TokenType.CUSTOM_TOKEN
      });
      this.openfortPlayerId = response.id;
      console.log('Finish authenticateWithThirdPartyProvider, response=' + JSON.stringify(response));
    } catch (error) {
      this.handleWalletCreationError();
      console.error('Error authenticateWithThirdPartyProvider, error=' + JSON.stringify(error));
      throw error;
    }
  }

  async configureEmbeddedSigner(recoveryPassword: string) {
    console.log('Start configureEmbeddedSigner:');
    try {
      await this.openfort.configureEmbeddedSigner(this.chainId, null, recoveryPassword);
      console.log('Finish configureEmbeddedSigner');
    } catch (error) {
      this.handleWalletCreationError();
      console.error('Error configureEmbeddedSigner, error=' + JSON.stringify(error));
      throw error;
    }
  }

  async getOpenfortAccount() {
    console.log('Start getAccount:');
    try {
      const response = await this.openfort.getAccount();
      console.log('Finish getAccount, response=' + JSON.stringify(response));
      this.saveWalletAddress(response.address, response.chainId);
    } catch (error) {
      this.handleWalletCreationError();
      console.error('Error getAccount, error=' + JSON.stringify(error));
      throw error;
    }
  }

  saveWalletAddress(address: string, chainId: number) {
    if (!this.openfortPlayerId) {
      return;
    }

    const data: WalletData = { walletAddress: address, chainId: chainId };

    const { firstName, lastName, username, imageUrl } = this.userService.userInfo$.getValue();
    const updateUserInfo$ = this.userService.updateUserInfoRequest({
      openfortPlayerId: this.openfortPlayerId,
      firstName,
      lastName,
      username,
      imageUrl
    });

    const saveWalletAddress$ = this.saveWalletAddressRequest(data);

    forkJoin([updateUserInfo$, saveWalletAddress$]).subscribe(([updateUserInfoResponse, saveWalletAddressResponse]) => {
      if (updateUserInfoResponse.success && saveWalletAddressResponse.success) {
        this.openfortPlayerId = undefined;
        if (this.authService.returnUrl) {
          this.authService.handleRedirect();
        }
      }
      this.dialogService.closeAll();
      this.loaderService.hideLoader();
    });
  }

  handleWalletCreationError() {
    this.toastrService.error('Something Went Wrong During Wallet Creation – Please Try Again Later');
    this.dialogService.closeAll();
    this.loaderService.hideLoader();
  }

  saveWalletAddressRequest(data: WalletData): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(`${environment.gaiminApi}/wallet/save`, data, {
      headers: this.authService.authorizationHeader()
    });
  }
}
